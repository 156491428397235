import React from 'react';
import CollectionDetail from '../../components/collection/CollectionDetail';
import Breadcrumb from '../../components/collection/CollectionBreadcrumb';
import MainLayout from '../../components/layout/MainLayout';
import SearchEngineOptimization from '../../components/utility/seo';

const AllProductPage = () => {
  return (
    <MainLayout>
      <SearchEngineOptimization title="สินค้าทั้งหมด" />
      <Breadcrumb type="productList" />
      <CollectionDetail withFilter />
    </MainLayout>
  );
};

export default AllProductPage;
